<template>
  <div>
    <div class="loca_list" style="display: none">
      <div
        @click="setLocalItem(item.category_code, item.id)"
        v-for="(item, index) in endCategory"
        :key="index"
        :class="{ on: parseInt(nowIdCp) === parseInt(item.id) }"
      >
        <a href="#">{{ item.title }}</a>
      </div>
    </div>
    <div class="back" style="display: none" @click="closeLocalItem"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      parentId: 0,
    };
  },
  props: {
    nowId: {
      type: String,
      default: "0",
    },
  },
  computed: {
    ...mapState("review", ["result", "msg", "endCategory"]),
    nowIdCp() {
      //   console.log(this.nowId);
      return this.nowId;
    },
  },
  methods: {
    setLocalItem(category_code, id) {
      document.querySelector(".loca_list").style.display = "none";
      document.querySelector(".back").style.display = "none";
      if (parseInt(this.nowId) === parseInt(id)) {
        return false;
      }
      this.$emit("moveCategory", {
        category_code,
        parentId: this.parentId,
        id,
      });
    },
    closeLocalItem() {
      // console.log(111);
      document.querySelector(".loca_list").style.display = "none";
      document.querySelector(".back").style.display = "none";
    },
    async showSelectList() {
      document.querySelector(".loca_list").style.display = "block";
      document.querySelector(".back").style.display = "block";
      this.parentId = document.querySelector(".shape_yellow").dataset.id;
      await this.$store.dispatch("review/getEndCategory", {
        id: this.parentId,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
    },
  },
};
</script>

<style lang="scss">
.back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 97;
}
</style>
