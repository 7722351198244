<template>
  <div class="rn_ms_section">
    <!---->
    <!--상품-->
    <div class="cate_wrap">
      <div class="cate_left">
        <router-link to="/review_goods_category_list"
          ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
        /></router-link>
      </div>
      <div class="cate_right">
        <!-- -->
        <ReviewGoodsCategory
          v-if="categories && categoryInfo"
          :category="categories"
          :id="!cateId ? categoryInfo[0].id : cateId"
          ref="goodsCategory"
          @getGoodsCategory="getGoodsCategory"
        />
        <!-- -->
      </div>
    </div>

    <!---->
    <div class="rn_ms_box_9">
      <!---->
      <ReviewGoodsFilter
        v-if="reviewGoodsMenu"
        :reviewGoodsMenu="reviewGoodsMenu"
        :option1="option1"
        :option2="option2"
        :option3="option3"
        ref="reviewGoodsFilter"
        @changOption="changOption"
      />
      <div class="rn_ms_empty_20"></div>
      <!---->
      <div class="sect_g_sort">
        <div class="left">
          <router-link
            to=""
            :class="{ on: soldOut }"
            @click.native="soldOutCheck"
            >품절<br />제외</router-link
          >
        </div>
        <div class="right">
          <!--상품(소비자-일반)-->
          <div v-if="parseInt(goodsSeq) === 0 && parseInt(this.option1) === 1">
            <router-link
              to=""
              :class="{ on: sort === 'default' }"
              @click.native="changeSort('default')"
              >각 제품의 리뷰 신뢰도 1위가 매긴 평점순</router-link
            >
          </div>
          <!--상품(소비자-특정)//상품(전문가)-->
          <div
            v-if="
              parseInt(goodsSeq) > 0 ||
              parseInt(this.option1) === 2 ||
              parseInt(this.option1) === 0
            "
          >
            <router-link
              to=""
              :class="{ on: sort === 'trust_score' }"
              @click.native="changeSort('trust_score')"
              >구매자들의 리뷰 신뢰도순</router-link
            >
            <!-- <router-link to="">급상승 리뷰순</router-link> -->
          </div>
          <!--상품(판매자)-->
          <div v-if="parseInt(this.option1) === 3">
            <router-link
              to=""
              :class="{ on: sort === 'trust_score' }"
              @click.native="changeSort('trust_score')"
              >각 제품의 리뷰 신뢰도 순</router-link
            >
          </div>
          <div>
            <router-link
              v-if="parseInt(this.option1) !== 3"
              to=""
              :class="{ on: sort === 'trust' }"
              @click.native="changeSort('trust')"
              >리뷰어 신뢰도순</router-link
            >
            <router-link
              to=""
              :class="{ on: sort === 'latest' }"
              @click.native="changeSort('latest')"
              >최신순</router-link
            >
            <router-link
              to=""
              :class="{ on: sort === 'comment' }"
              @click.native="changeSort('comment')"
              >댓글순</router-link
            >
          </div>
        </div>
      </div>
      <div class="location">
        <span>상품 ></span>
        <template v-for="(name, index) in categoryName">
          <span
            to=""
            :key="index"
            :id="`${categoryName.length}_${index}`"
            :data-level="name.level"
            :data-id="name.id"
            :class="{
              shape_yellow: parseInt(name.level) === 4,
            }"
            @click="goCategorMain(name.level)"
            v-if="categoryName.length - 1 > index"
            >{{
              parseInt(name.level) !== 4 ? `&nbsp;${name.title}` : name.title
            }}
            >
          </span>
          <span
            :class="{
              on: !goodsSeq,
              shape_yellow: goodsSeq,
              shape_red: parseInt(name.level) === 5 && !goodsSeq,
            }"
            v-if="categoryName.length - 1 <= index"
            :key="index"
            :id="`${categoryName.length}_${index}`"
            :data-id="name.id"
            :data-level="name.level"
            @click="returnCategory($event)"
            >{{
              goodsSeq || (parseInt(name.level) === 5 && !goodsSeq)
                ? name.title
                : `&nbsp;${name.title}`
            }}{{ goodsSeq && listArray.length > 0 ? "&nbsp;>" : "" }}
          </span>
        </template>
        <ReviewGoodsEndCategoryVue
          v-if="categoryName.length > 0"
          :nowId="categoryName[categoryName.length - 1].id"
          @moveCategory="moveCategory"
          ref="end_category"
        />
        <span v-if="goodsSeq && listArray.length > 0" class="on"
          >&nbsp;{{ listArray[0].main_goods_name }}
        </span>
      </div>
      <div class="sect_list" v-for="(item, index) in listArray" :key="item.seq">
        <ReviewGoodsListItem
          v-if="categoryName.length > 0"
          :item="item"
          :index="parseInt(rank) !== 0 ? parseInt(rank) : index + 1"
          :nowPage="pageSnsUrl"
          :category="categoryName[0].id"
          :goods="isList"
          :ref="`listItem_${item.seq}`"
          :id="`listItem_${item.seq}`"
          :paramsQuery="paramsQuery"
          @goDetailList="goDetailList"
          @setSeq="setSeq"
        />
      </div>
      <div class="sect_list none_data" v-if="listArray.length <= 0">
        <p>검색된 리뷰가 없습니다.</p>
      </div>
      <div class="rn_more" v-if="totalPage > page">
        <router-link to="" @click.native="getReviewGoodsList()"
          >{{ limit }}개 더보기<img
            src="@/assets/images/sub/my_down_ico.png"
            alt=""
        /></router-link>
      </div>
      <div class="rn_more" v-if="totalPage <= page && parseInt(seq) !== 0">
        <router-link to="" @click.native="resetSeq"
          >목록으로<img src="@/assets/images/sub/my_down_ico.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewGoodsCategory from "@/components/review/state/ReviewGoodsCategory";
import ReviewGoodsFilter from "@/components/review/state/ReviewGoodsFilter";
import ReviewGoodsListItem from "@/components/review/state/ReviewGoodsListItem";
import ReviewGoodsEndCategoryVue from "@/components/review/state/ReviewGoodsEndCategory.vue";
export default {
  data() {
    return {
      soldOut: false,
      showPage: true,
      menu: 1,
      category_code: this.$route.params.category || null,
      cateId: parseInt(this.$route.params.level2Cate) || null,
      option1: parseInt(this.$route.query.option1) || 1,
      option2: parseInt(this.$route.query.option2) || 0,
      option3: parseInt(this.$route.query.option3) || 0,
      page: parseInt(this.$route.query.page) || 0,
      goodsSeq: parseInt(this.$route.query.goods_seq) || 0,
      sort:
        parseInt(this.$route.query.goods_seq) > 0 ||
        parseInt(this.$route.query.option1) === 2 ||
        parseInt(this.$route.query.option1) === 3
          ? "trust_score"
          : "default",

      seq: this.$route.query.seq || 0,
      pageName: "/review/goods_main",
      option1Backup: parseInt(this.$route.query.option1) || 1,
      urlGoodsSeq: 0,
      checkShape: false,
    };
  },
  mounted() {
    this.getCategories();
  },
  components: {
    ReviewGoodsCategory,
    ReviewGoodsFilter,
    ReviewGoodsListItem,
    ReviewGoodsEndCategoryVue,
  },
  computed: {
    ...mapState("review", [
      "mainMenuList",
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
      "categoryName",
      "rank",
    ]),
    ...mapState("shop", ["categories", "reviewGoodsMenu"]),
    paramsQuery() {
      if (parseInt(this.option1) === 2 || parseInt(this.option1) === 3) {
        return `category_code=${this.category_code}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`;
      }
      return `category_code=${this.category_code}&goods_seq=${this.urlGoodsSeq}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`;
    },
    pageSnsUrl() {
      // console.log(
      //   `${process.env.VUE_APP_API_URL}${this.pageName}/${this.category_code}?goods_seq=${this.goodsSeq}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`
      // );
      if (parseInt(this.option1) === 2 || parseInt(this.option1) === 3) {
        return `${process.env.VUE_APP_API_URL}${this.pageName}/${this.category_code}?option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`;
      }
      return `${process.env.VUE_APP_API_URL}${this.pageName}/${this.category_code}?goods_seq=${this.urlGoodsSeq}&option1=${this.option1}&option2=${this.option2}&option3=${this.option3}`;
    },
    categoryInfo() {
      if (this.categories.length > 0) {
        if (this.category_code === null) {
          return [
            {
              index: 0,
              id: parseInt(this.categories[0].id),
              category_code: this.categories[0].category_code,
              title: this.categories[0].title,
              des: "카테고리코드가 없을떄",
            },
          ];
        }

        let index = this.categories.findIndex(
          (cate) => cate.category_code === this.category_code.substr(0, 4)
        );

        if (index >= 0) {
          return [
            {
              index: index,
              id: parseInt(this.categories[index].id),
              category_code: this.categories[index].category_code,
              title: this.categories[index].title,
              des: "인덱스가 있을때",
            },
          ];
        }
      }
      return [
        {
          index: 0,
          id: null,
          category_code: process.env.VUE_APP_DEFAULT_GOOD, //상품의 기본 기초코드
          title: null,
          des: "널 일때 ",
        },
      ];
    },
    nowUrl() {
      if (parseInt(this.option1) === 3) {
        return `${this.pageName}/${this.category_code}?option1=${
          this.option1
        }&option2=${this.option2}&option3=${this.option3}&seq=${
          !this.seq ? "0" : this.seq
        }`;
      }
      return `${this.pageName}/${this.category_code}?goods_seq=${
        this.urlGoodsSeq
      }&option1=${this.option1}&option2=${this.option2}&option3=${
        this.option3
      }&seq=${!this.seq ? "0" : this.seq}`;
    },
    isList() {
      return parseInt(this.goodsSeq) > 0 || parseInt(this.option1) !== 1;
    },
  },
  methods: {
    reSetView() {
      this.cateId = null;
      this.seq = 0;
      this.goodsSeq = 0;
      this.category_code = null;
      this.option1 = 1;
      this.option2 = 0;
      this.option3 = 0;
      this.getCategories();
    },
    resetSeq() {
      this.seq = 0;
      this.goods_seq = 0;
      // this.sort = "default";
      this.sort = "trust_score";
      // if (parseInt(this.option1) === 2 || parseInt(this.option1) === 3) {
      //   this.sort = "trust_score";
      // }
      this.resetDetail();
      this.getReviewGoodsList(true);
    },
    goCategorMain(level) {
      if (parseInt(level) !== 4) return false;
      this.$router.push(
        `/review_goods_category_list/open?cate1=${this.categoryName[0].id}&cate2=${this.categoryName[1].id}&cate3=${this.categoryName[2].id}&category_code=${this.categoryName[0].category_code}`
      );
    },
    moveCategory(payload) {
      this.cateId = parseInt(payload.parentId);
      this.category_code = payload.category_code;
      this.$router
        .push(`/review/goods_main/${this.cateId}/${this.category_code}`)
        .catch(() => {});
      // console.log(this.cateId, this.category_code);
      this.optionsInitial();
      this.$refs.reviewGoodsFilter.changeMenu("1", this.option1, false);
      this.$refs.reviewGoodsFilter.changeMenu("2", this.option2, false);
      this.$refs.reviewGoodsFilter.changeMenu("3", this.option3, false);
      this.getReviewGoodsList(true);
    },
    soldOutCheck() {
      this.soldOut = !this.soldOut;
      this.getReviewGoodsList(true);
    },
    async getCategories() {
      // console.log(this.category_code, 2);
      await this.$store.dispatch("shop/getCategories");
      // console.log("this.cateId", this.cateId);
      if (!this.cateId) {
        this.$refs.goodsCategory.reflash(this.categoryInfo[0].index);
      } else {
        const cateIndex = this.categories.findIndex(
          (cate) => parseInt(cate.id) === parseInt(this.cateId)
        );
        // console.log(cateIndex);
        this.$refs.goodsCategory.reflash(cateIndex);
      }
      this.category_code = !this.category_code
        ? this.categoryInfo[0].category_code
        : this.category_code;
      // console.log(this.category_code, 3);
      this.getReviewGoodsList(true);
    },
    async getReviewGoodsList(reset = false) {
      // console.log(this.category_code, 1);
      if (reset) {
        this.page = 0;
      }

      await this.$store.dispatch("review/getReviewGoodsList", {
        option_category1: this.option1,
        option_category2: this.option2,
        option_category3: this.option3 == 0 ? "" : this.option3,
        category_code: this.category_code,
        sort: this.sort,
        page: this.page,
        main_menu_seq: this.menu,
        reset,
        sold_out_yn: this.soldOut ? "Y" : "N",
        seq: this.seq || 0,
        is_list: this.isList,
        goods_seq:
          parseInt(this.goodsSeq) === 0 || !this.goodsSeq
            ? null
            : this.goodsSeq,
      });
      if (this.result) {
        if (parseInt(this.seq)) {
          if (this.$refs[`listItem_${this.seq}`][0]) {
            this.$refs[`listItem_${this.seq}`][0].openDetail();
          }
        }
      }
      if (!this.result) {
        this.$toast.default(this.msg);
      }

      this.page++;
    },
    changeSort(sort) {
      // console.log(sort);
      const box2 = document.querySelectorAll(".box_2.detail");
      [...box2].map((box) => (box.style.display = "none"));
      this.sort = sort;
      this.seq = 0;
      this.getReviewGoodsList(true);
    },
    goDetailList(goods_seq) {
      this.goodsSeq = parseInt(goods_seq);
      // if (this.sort === "default") {
      //   this.sort = "trust_score";
      // }
      this.category_code =
        this.categoryName[this.categoryName.length - 1]["category_code"];
      this.sort = "trust_score";
      this.seq = 0;
      this.resetDetail();
      this.goPage();
      this.getReviewGoodsList(true);
    },
    goPage() {
      if (this.$route.fullPath === this.nowUrl) return false;
      this.$router.push(this.nowUrl).catch(() => {});
    },

    changOption(op1, op2, op3) {
      this.option1 = parseInt(op1);
      this.option2 = parseInt(op2);
      this.option3 = parseInt(op3);
      this.seq = 0;
      this.goodsSeq = 0;

      this.sort = "default";
      if (
        parseInt(this.option1) === 0 ||
        parseInt(this.option1) === 2 ||
        parseInt(this.option1) === 3
      ) {
        this.sort = "trust_score";
      }

      this.resetDetail();
      this.goPage();
      // console.log(2);
      this.getReviewGoodsList(true);
    },
    goCategory(event) {},
    setSeq(seq) {
      // this.seq = seq;
      if (parseInt(seq) > 0) {
        const goods = this.listArray.filter(
          (list) => parseInt(list.seq) === parseInt(seq)
        );
        this.urlGoodsSeq = goods[0].goods_seq;
      }

      const detail = document.querySelector(`#item_detail_${seq}`);
      const div = document.querySelector(`#listItem_${seq}`);

      if (detail) {
        div.scrollIntoView({ behavior: "smooth" });
      }
      // this.goPage();
    },
    optionsInitial() {
      this.seq = 0;
      this.option1 = 1;
      this.option2 = 0;
      this.option3 = 0;
      this.sort = "default";
      this.goodsSeq = 0;
    },
    getGoodsCategory(id) {
      if (parseInt(this.cateId) === 0) {
        this.cateId = parseInt(id);
      }
      if (parseInt(this.cateId) !== parseInt(id)) {
        const category = this.categories.filter(
          (cate) => parseInt(cate.id) === parseInt(id)
        );
        this.category_code = category[0].category_code;

        this.optionsInitial();
        this.$refs.reviewGoodsFilter.changeMenu("1", this.option1, false);
        this.$refs.reviewGoodsFilter.changeMenu("2", this.option2, false);
        this.$refs.reviewGoodsFilter.changeMenu("3", this.option3, false);
        this.resetDetail();
        this.goPage();

        // console.log(4);
        // console.log(this.categoryInfo, this.category_code, id);

        this.getReviewGoodsList(true);
      } else {
        const index = this.categories.findIndex(
          (cate) => parseInt(cate.id) === parseInt(this.cateId)
        );
        this.$refs.goodsCategory.goSlide(index);
        this.cateId = null;
      }
    },
    returnCategory(e) {
      console.log(e);
      if (e.target.classList.contains("shape_red")) {
        this.$refs["end_category"].showSelectList();
        return true;
      }

      let id = null;
      if (this.cateId) {
        id = this.cateId;
      } else {
        id = this.categoryInfo[0].id;
      }

      this.goodsSeq = 0;
      // this.option1 = "1";
      // this.option2 = "0";
      // this.option3 = "0";
      this.sort = "default";
      if (parseInt(this.option1) === 2 || parseInt(this.option1) === 3) {
        this.sort = "trust_score";
      }
      this.seq = 0;
      this.page = 0;
      this.resetDetail();
      // this.$refs.reviewGoodsFilter.changeMenu("1", this.option1, false);
      // this.$refs.reviewGoodsFilter.changeMenu("2", this.option2, false);
      // this.$refs.reviewGoodsFilter.changeMenu("3", this.option3, false);

      this.$router
        .push(`/review/goods_main/${id}/${this.category_code}`)
        .catch(() => {});
      this.getReviewGoodsList(true);
    },
    resetDetail() {
      const snsClass = document.querySelectorAll(".sns_box");
      const declPop = document.querySelectorAll(".declaration_pop");
      const replyList = document.querySelectorAll(".reply_list_box");
      const arrowReply = document.querySelectorAll(".arrow_reply");
      const infoWord = document.querySelectorAll(".info_word");
      const arrowInfo = document.querySelectorAll(".arrow_info");
      const detail = document.querySelectorAll(".detail");
      const detailArrow = document.querySelectorAll(".detail_arrow");

      [...infoWord].map((word) => {
        word.style.display = "none";
      });
      [...arrowInfo].map((info) => (info.textContent = "∨"));
      [...snsClass].map((sns) => {
        sns.style.display = "none";
      });
      [...declPop].map((decl) => {
        decl.style.display = "none";
      });
      [...replyList].map((reply) => {
        reply.style.display = "none";
      });
      [...arrowReply].map((reply) => (reply.textContent = "∨"));
      [...detailArrow].map((deatil) => (deatil.textContent = "∨"));
      [...detail].map((d) => (d.style.display = "none"));
    },
  },
};
</script>

<style lang="scss" scoped>
.sect_list {
  &.none_data {
    text-align: center;
    padding: 10px 0px 0px 0px;
  }
}
.shape_red::before,
.shape_yellow::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;
  border-radius: 50%;
}
.shape_yellow::before {
  background: $primary-color;
}
.shape_red::before {
  background: red;
}
</style>
